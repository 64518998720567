.theForm{
    margin: auto;
    width: 50vw;
    padding-top: 10vh;
    position: relative;
    @media (max-width: 767px) {
        width: 90vw;
    }
    .datePicker{
        width: 100%;
    }
}